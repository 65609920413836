import { ready } from 'brei-project-utils';

import { aggregate } from './modules/aggregate';
import { forms } from './modules/forms';

const results = {
	init() {
		forms.init();
		aggregate.init();
	}
};

ready(function () {
	results.init();
});
